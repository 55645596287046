<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            :options="$LIST_PENGASUHAN"
            v-model="selJenjang"
            outlined
            @input="getListMusyrif()"
            label="Jenjang"
            style="min-width: 130px;"
          ></q-select>
          <q-btn
            icon="add"
            color="primary"
            label="tambah pembina"
            :to="'/pengasuhaneditmusyrif/-1/' + selJenjang"
          ></q-btn>
        </div>

        <q-input outlined label="Cari Musyrif/NIK" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nik</th>
            <th>nama</th>
            <th style="width:20px;">Musyrif</th>
            <th style="width:20px;">Murabbi Tahfidz</th>
            <th style="width:20px;">Murabbi Mentoring</th>
            <th style="width:20px;">Waka Pengasuhan</th>
            <th style="width:20px;">Kepala Pengasuhan</th>
            <th style="width:20px;">Super Admin</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filMusyrif" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nik }}</td>
            <td>{{ val.nama }}</td>
            <td class="text-center">
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_musyrif"
              ></q-checkbox>
            </td>
            <td class="text-center">
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_murabbi_tahfidz"
              ></q-checkbox>
            </td>
            <td class="text-center">
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_murabbi_mentor"
              ></q-checkbox>
            </td>
            <td class="text-center">
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_waka"
              ></q-checkbox>
            </td>
            <td class="text-center">
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_kepala_pengasuhan"
              ></q-checkbox>
            </td>
            <td class="text-center">
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_super_user"
              ></q-checkbox>
            </td>
            <td>
              <q-btn
                flat
                dense
                icon="edit"
                color="secondary"
                :to="'/pengasuhaneditmusyrif/' + val.id + '/' + selJenjang"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-section align="right">
        <q-btn outline color="primary" @click="exportExcel()"
          >export excel</q-btn
        >
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      selJenjang: null,
      listMusyrif: [],
      searchTerm: "",
    };
  },
  mounted() {
    this.selJenjang = this.$route.params.jenjang;
    this.getListMusyrif();
  },
  computed: {
    filMusyrif() {
      if (this.searchTerm == "") return this.listMusyrif;
      else {
        return this.listMusyrif.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNIK = val.nik.indexOf(this.searchTerm) != -1;
          return cNama || cNIK;
        });
      }
    },
  },
  methods: {
    async exportExcel() {
      let temp = this.listMusyrif.slice(0);
      temp.forEach((el) => {
        delete el.password;
      });
      var ws = this.$xlsx.utils.json_to_sheet(temp);
      var wb = this.$xlsx.utils.book_new();
      this.$xlsx.utils.book_append_sheet(wb, ws, `Pembina ${this.selJenjang}`);

      var now = moment().format("DD MMM YYYY");
      this.$xlsx.writeFile(wb, `Data Musyrif/ah ${now}.xlsx`);
    },
    async getListMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          "/pengasuhan/musyrif/list/" + this.selJenjang,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listMusyrif = resp.data;
        });
      if (this.selJenjang != this.$route.params.jenjang)
        this.$router.replace({
          path: "/pengasuhanlistmusyrif/" + this.selJenjang,
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
